import { useEffect } from 'react';
import auth from '@motimate/auth';
import { useRouter } from 'next/router';
import yn from 'yn';
import { useOneAppFeatureFlags } from '@/lib/hooks/useOneAppFeatureFlags';
var OrganizationPage = function OrganizationPage() {
  var router = useRouter();
  var _useOneAppFeatureFlag = useOneAppFeatureFlags(),
    oneAppFlags = _useOneAppFeatureFlag.oneAppFlags;
  useEffect(function () {
    var organization = router.query.organization;
    if (!organization) return;
    var defaultRedirect = "/".concat(organization, "/training/my");
    if (!(oneAppFlags !== null && oneAppFlags !== void 0 && oneAppFlags.training) && oneAppFlags !== null && oneAppFlags !== void 0 && oneAppFlags.wall) {
      defaultRedirect = "/".concat(organization, "/pulse");
    }
    if (yn(process.env.FEATURE_TODAY_SCREEN)) {
      defaultRedirect = "/".concat(organization, "/today");
    }
    if (typeof organization === 'string' && auth.isAuthenticated(organization)) {
      router.push(defaultRedirect);
      return;
    }
    var stateParam = auth.storeState(defaultRedirect);
    router.push("/".concat(organization, "/signin?state=").concat(stateParam));
  }, [router]);
  return null;
};
export default OrganizationPage;